import React from 'react';
import { Box, Text, Alert, AlertIcon } from "@chakra-ui/react";
import ImpactSettingsHeading from "./ImpactSettingsHeading";
import ImpactSettingsRadio from "./ImpactSettingsRadio";
import MinimumOrderAmountSettings from "./MinimumOrderAmountSettings";
import TreePlantingSettings from "./TreePlantingSettings";
import PlasticRemovalSettings from "./PlasticRemovalSettings";

function ImpactSettingsSection({ formStore, setFormStore, compStore }) {
  const [validationErrors, setValidationErrors] = React.useState([]);

  React.useEffect(() => {
    validateAll();
  }, [formStore]);

  const validateAll = () => {
    const errors = [];

    // Validate tree planting
    if (formStore.environmental_impacts.includes("tree_planting")) {
      if (formStore.selected_option === "fixed_monthly" && formStore.num_trees < 10) {
        errors.push("Monthly subscriptions require a minimum of 10 trees");
      } else if (formStore.num_trees <= 0) {
        errors.push("Please enter a number of trees greater than 0");
      }
    }

    // Validate plastic removal
    if (formStore.environmental_impacts.includes("plastic_removal")) {
      if (formStore.selected_option === "fixed_monthly" && formStore.num_bottles < 10) {
        errors.push("Monthly subscriptions require a minimum of 10 plastic bottles");
      } else if (formStore.num_bottles <= 0) {
        errors.push("Please enter a number of bottles greater than 0");
      }
    }

    // Validate minimum order amount
    if (formStore.minimum_order_amount_enabled && formStore.minimum_order_amount <= 0) {
      errors.push("Minimum order amount must be greater than 0");
    }

    setValidationErrors(errors);
    return errors.length === 0;
  };

  return (
    <Box>
      <ImpactSettingsHeading formStore={formStore} />
      
      {validationErrors.length > 0 && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          <Box>
            {validationErrors.map((error, index) => (
              <Text key={index} fontSize="sm">{error}</Text>
            ))}
          </Box>
        </Alert>
      )}

      <ImpactSettingsRadio formStore={formStore} setFormStore={setFormStore} compStore={compStore} />
      <TreePlantingSettings formStore={formStore} setFormStore={setFormStore} />
      <PlasticRemovalSettings formStore={formStore} setFormStore={setFormStore} />
      <MinimumOrderAmountSettings formStore={formStore} setFormStore={setFormStore} />
    </Box>
  );
}

export default ImpactSettingsSection; 