import React from 'react';
import { Box, Text } from "@chakra-ui/react";

function ImpactSettingsHeading({ formStore, compStore }) {
  return (
    <Box mb={6}>
      <Text fontSize="xl" fontWeight="medium" mb={2}>
        {formStore.environmental_impacts.includes("tree_planting") && formStore.environmental_impacts.includes("plastic_removal") ?
          "Planting & Plastic Removal Settings" :
          formStore.environmental_impacts.includes("tree_planting") ? "Planting Settings" : "Plastic Removal Settings"
        }
      </Text>
      <Text lineHeight="150%" mb={8} textColor="gray.500">
        Personalize your app settings below to fit your business and environmental needs.
      </Text>
    </Box>
  );
}

export default ImpactSettingsHeading; 