import React from 'react';
import { Box, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Text } from "@chakra-ui/react";

function TreePlantingSettings({ formStore, setFormStore }) {
  const [error, setError] = React.useState("");

  const validateTrees = (value) => {
    if (formStore.selected_option === "fixed_monthly" && value < 10) {
      setError("Monthly subscriptions require a minimum of 10 trees");
      return false;
    }
    if (value <= 0) {
      setError("Please enter a number of trees greater than 0");
      return false;
    }
    setError("");
    return true;
  };

  React.useEffect(() => {
    if (formStore.environmental_impacts.includes("tree_planting")) {
      const isValid = validateTrees(formStore.num_trees);
      setFormStore(prev => ({
        ...prev,
        hasValidationError: !isValid
      }));
    }
  }, [formStore.selected_option, formStore.num_trees]);

  return (
    formStore.environmental_impacts.includes("tree_planting") && (
      <Box mb={4}>
        <FormLabel fontWeight="medium">Number of trees</FormLabel>
        <NumberInput
          value={formStore.num_trees}
          min={formStore.selected_option === "fixed_monthly" ? 10 : 0}
          isInvalid={error !== ""}
          onChange={(valueString) => {
            const value = valueString === '' ? 0 : Number(valueString);
            const isValid = validateTrees(value);
            setFormStore({
              ...formStore,
              num_trees: value,
              hasValidationError: !isValid
            });
          }}
        >
          <NumberInputField bgColor="white" borderRadius="10" />
          <NumberInputStepper pr="2">
            <NumberIncrementStepper color="gray.400" border="0" />
            <NumberDecrementStepper color="gray.400" border="0" />
          </NumberInputStepper>
        </NumberInput>
        {error && (
          <Text fontSize="sm" color="red.500" mt={2}>
            {error}
          </Text>
        )}
      </Box>
    )
  );
}

export default TreePlantingSettings; 