import {
    Box,
    Button,
    HStack,
    Stack,
    Badge,
    Spacer,
    Flex,
    Heading,
    VStack,
    Text,
    Center,
    SimpleGrid,
    Spinner,
    useBoolean,
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Link,
    useColorModeValue,
    useColorMode,
    useDisclosure,
    Menu,
    MenuButton,
    Form,
    FormLabel,
    Input,
    MenuList,
    MenuItem,
    MenuDivider,
    Checkbox,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    FormControl,
    RadioGroup,
    Radio,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    TabPanel,
    Image,
    Select,
    CheckboxGroup
} from "@chakra-ui/react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";

import { useAuthState } from "react-firebase-hooks/auth";
import { HTTPGet, HTTPPost } from "../httputils";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import BillingModal from "./BillingModal";
import BillingInfoAlert from "./BillingInfoAlert";
import CustomSproutButton from "./CustomSproutButton";
import { SaveImpactSettings } from "../backend";

import firebase from "../firebase";
import EnvironmentalImpactCheckboxes from "./EnvironmentalImpactCheckboxes";
import ImpactSettingsSection from "./ImpactSettingsSection";
import AppSettingsSidebar from "./AppSettingsSidebar";
import { useFormStore, useUnsavedChangesDetection, useAppSettingsDiff, getSelectedOption, getDefaultFormStore, getMinimumOrderAmountEnabled, getMinimumOrderAmount } from "./hooks";

export default function AppSettingsV2({ compStore, setCompStore }) {  
  const [showBillingModal, setShowBillingModal] = React.useState(false);
  const [user, loading] = useAuthState(firebase.auth());
  const [unsavedChanges, setUnsavedChanges] = React.useState("");
  const [formStore, setFormStore] = React.useState(getDefaultFormStore(compStore));
  const [showAlert, setShowAlert] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({
    trees: false,
    bottles: false,
    minimumOrder: false
  });

  useAppSettingsDiff(compStore, formStore, setUnsavedChanges);

  React.useEffect(() => {
    console.log("CompStore integration data:", {
      impact_settings: compStore.current_integration?.impact_settings,
      selectedOption: {
        tree_planting: compStore.current_integration?.impact_settings.find(s => s.tree_planting)?.tree_planting?.selected_option,
        plastic_removal: compStore.current_integration?.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.selected_option
      }
    });
  }, [compStore.current_integration]);

  React.useEffect(() => {
    if (formStore.environmental_impacts.length === 0) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [formStore.environmental_impacts]);

  // Validate inputs whenever they change
  React.useEffect(() => {
    const errors = {
      trees: formStore.environmental_impacts.includes("tree_planting") && 
             formStore.selected_option === "fixed_monthly" && 
             (!formStore.num_trees || formStore.num_trees < 10),
      bottles: formStore.environmental_impacts.includes("plastic_removal") && 
               formStore.selected_option === "fixed_monthly" && 
               (!formStore.num_bottles || formStore.num_bottles < 10),
      minimumOrder: formStore.minimum_order_amount_enabled && 
                   (!formStore.minimum_order_amount || formStore.minimum_order_amount <= 0)
    };
    setValidationErrors(errors);
  }, [formStore]);

  function saveButton() {
    const hasValidationErrors = Object.values(validationErrors).some(error => error) || 
                              formStore.hasValidationError || 
                              showAlert;

    const label = compStore.current_integration?.status === "active" ? "Update" : "Save and Activate";

    const getErrorMessage = () => {
      if (validationErrors.trees) return "Monthly tree planting requires at least 10 trees";
      if (validationErrors.bottles) return "Monthly plastic removal requires at least 10 bottles";
      if (validationErrors.minimumOrder) return "Minimum order amount must be greater than 0";
      if (showAlert) return "Please select at least one environmental impact option";
      if (formStore.hasValidationError) {
        if (formStore.environmental_impacts.includes("tree_planting") && formStore.num_trees <= 0) {
          return "Please enter a number of trees greater than 0";
        }
        if (formStore.environmental_impacts.includes("plastic_removal") && formStore.num_bottles <= 0) {
          return "Please enter a number of plastic bottles greater than 0";
        }
      }
      return "";
    };

    // Check if any values are invalid
    const hasInvalidValues = () => {
      if (formStore.environmental_impacts.includes("tree_planting") && formStore.num_trees <= 0) return true;
      if (formStore.environmental_impacts.includes("plastic_removal") && formStore.num_bottles <= 0) return true;
      if (formStore.minimum_order_amount_enabled && formStore.minimum_order_amount <= 0) return true;
      return false;
    };

    const isValid = 
      !hasValidationErrors && 
      !hasInvalidValues() &&
      unsavedChanges !== "" &&
      formStore.environmental_impacts.length > 0;

    const handleSave = async () => {
      if (hasValidationErrors || hasInvalidValues()) {
        console.log("Validation errors present:", validationErrors);
        return;
      }

      const saveData = {
        tree_planting: formStore.environmental_impacts.includes("tree_planting"),
        plastic_removal: formStore.environmental_impacts.includes("plastic_removal"),
        selected_option:  formStore.selected_option,
        num_trees: parseInt(formStore.num_trees),
        num_bottles: parseInt(formStore.num_bottles),
        minimum_order_amount_enabled: formStore.minimum_order_amount_enabled,
        minimum_order_amount: formStore.minimum_order_amount_enabled ? formStore.minimum_order_amount : 0
      };

      console.log("Saving data to backend:", saveData);

      const token = await user.getIdToken(true);
      const resp = await SaveImpactSettings(token, compStore.current_integration.uuid, saveData);
      console.log("Save response:", resp);

      if (resp) {
        const updatedIntegration = resp.find(integration => integration.uuid === compStore.current_integration.uuid);
        if (updatedIntegration) {
          // Update both compStore and formStore to reflect the saved state
          setCompStore({
            ...compStore,
            current_integration: updatedIntegration,
            connected_integrations: resp,
          });

          // Update formStore to match the saved state
          setFormStore({
            ...formStore,
            environmental_impacts: updatedIntegration.environmental_impacts || ["tree_planting"],
            num_trees: updatedIntegration.impact_settings.find(s => s.tree_planting)?.tree_planting?.num_trees ?? 0,
            num_bottles: updatedIntegration.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.num_bottles ?? 0,
            planting_option: updatedIntegration.impact_settings.find(s => s.tree_planting)?.tree_planting?.selected_option || "per_product",
            plastic_removal_option: updatedIntegration.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.selected_option || "per_product",
            selected_option: updatedIntegration.impact_settings.find(s => s.tree_planting)?.tree_planting?.selected_option || 
                            updatedIntegration.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.selected_option || 
                            "per_product",
            minimum_order_amount_enabled: function() {
              const tree = updatedIntegration.impact_settings.find(s => s.tree_planting)?.tree_planting?.threshold_amt > 0
              const plastic = updatedIntegration.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.threshold_amt > 0
              return tree || plastic || false
            }(),
            minimum_order_amount: function() {
              const tree = updatedIntegration.impact_settings.find(s => s.tree_planting)?.tree_planting?.threshold_amt
              const plastic = updatedIntegration.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.threshold_amt
              return tree/100 || plastic/100 || 0
            }()
          });

          // Clear unsaved changes message
          setUnsavedChanges("");
        }
      }
    };

    return (
      <VStack spacing={2} align="stretch">
        <CustomSproutButton
          compStore={compStore}
          setCompStore={setCompStore}
          color="teal"
          isDisabled={!isValid}
          handler={handleSave}
          label={label}
        />
        {hasValidationErrors && (
          <Alert status="error" borderRadius="md" size="sm">
            <AlertIcon />
            <Text fontSize="sm">{getErrorMessage()}</Text>
          </Alert>
        )}
      </VStack>
    );
  }

  return (
    <Container maxW="1280px">
      <BillingModal show={showBillingModal} setShow={setShowBillingModal} compStore={compStore} setCompStore={setCompStore} />

      <Flex
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        gap="6"
      >
        <Box w={{ base: "100%", md: "67%", lg: "67%" }}>
          <Box
            mb={6}
            bgColor="#F4F8FB"
            borderRadius="24"
            p={{ base: "4", md: "8", lg: "8" }}
          >
            <EnvironmentalImpactCheckboxes formStore={formStore} setFormStore={setFormStore} compStore={compStore} />
            <ImpactSettingsSection formStore={formStore} setFormStore={setFormStore} compStore={compStore} />
          </Box>
        </Box>

        <AppSettingsSidebar
          compStore={compStore}
          unsavedChanges={unsavedChanges}
          saveButton={saveButton}
          formStore={formStore}
          setFormStore={setFormStore}
        />
      </Flex>
    </Container>
  );
}


