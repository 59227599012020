import React from 'react';
import {
    Box,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    FormControl,
    CheckboxGroup,
    Stack,
    Checkbox,
    Link,
    Icon,
    Tooltip,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { getCostPerTreeInCents, getCostPerBottleInCents } from './hooks';

function EnvironmentalImpactCheckboxes({ formStore, setFormStore, compStore }) {
  const costPerTree = getCostPerTreeInCents(compStore) / 100; // Convert cents to dollars
  const costPerBottle = getCostPerBottleInCents(compStore) / 100;

  return (
    <Box mb={6}>
      <Text fontSize="xl" fontWeight="medium" mb={2}>
        Environmental Impact Settings
      </Text>
      <Text lineHeight="150%" mb={8} textColor="gray.500">
        Select the environmental impact options you would like to offer your customers.
      </Text>
      {formStore.environmental_impacts.length === 0 && (
        <Alert status='warning' mb={4}>
          <AlertIcon />
          <AlertTitle>Warning!</AlertTitle>
          <AlertDescription>Please select at least one environmental impact option.</AlertDescription>
        </Alert>
      )}
      <FormControl>
        <CheckboxGroup colorScheme="teal" value={formStore.environmental_impacts} onChange={(value) => setFormStore({ ...formStore, environmental_impacts: value })}>
          <Stack spacing={[1, 5]} direction={['column']}>
            <Box display="flex" alignItems="center">
              <Checkbox value="tree_planting">Tree Planting (${costPerTree.toFixed(2)}/tree)</Checkbox>
              <Tooltip 
                label="Reforestation is done with our partner's Veritree"
                hasArrow
              >
                <Link href="https://thegoodapi.com/how-it-works" isExternal ml={1}>
                  <Icon as={InfoOutlineIcon} w={3} h={3} color="gray.500" />
                </Link>
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox value="plastic_removal">Plastic Removal (${costPerBottle.toFixed(2)}/bottle)</Checkbox>
              <Tooltip 
                label="Ocean bound plastic is removed by our partnership with Plastic Bank"
                hasArrow
              >
                <Link href="https://thegoodapi.com/plastic-removal" isExternal ml={1}>
                  <Icon as={InfoOutlineIcon} w={3} h={3} color="gray.500" />
                </Link>
              </Tooltip>
            </Box>
          </Stack>
        </CheckboxGroup>
      </FormControl>
    </Box>
  );
}

export default EnvironmentalImpactCheckboxes; 