import firebase from './firebase';
import { HTTPGet, HTTPPost, HTTPGetText, HTTPDelete } from './httputils';
 



async function GetTreeCountBadgeRender(token, integrationID) {
    var url = "/ss/api/badge/counter"
    var resp = await HTTPGetText(url, token, {"integration_id": integrationID})
    if (resp.status != 200) {
        return null
    }
    
    return resp.data
}

export async function CancelBilling(token){
    var url = "/api/user/billing"
    var resp = await HTTPDelete(url, token, {})
    if (resp.status != 200) {
        return null
    }

    return resp.data
}
export async function RefreshSettings(current_integration_id, compStore, setCompStore, token) {
    
    var integrations = await GetAllIntegrations(token)
    if (integrations == null) {
       integrations = []
    }

    const accountSettings = await GetAccountSettings(token);
    if (accountSettings == null) {
        console.log("Error loading account settings")
      }


    var current_integration = null
    for (var i = 0; i < integrations.length; i++) {
        if (integrations[i].uuid == current_integration_id) {
            current_integration = integrations[i]
            break
        }
    }

    setCompStore({
        ...compStore,
        global_account_settings: accountSettings,
        connected_integrations: integrations,
        current_integration: current_integration? current_integration : integrations[0],
     
      })


    return true
}
export async function UninstallProductBadge(token, integrationID) {
    var url = "/api/user/integration/" + integrationID + "/badge"
    var resp = await HTTPDelete(url, token, {
        "type": "product",
    })
    if (resp.status != 200) {
        return null
    }

    resp = await GetAllIntegrations(token)
    if (resp == null) {
        return null
    }

    return resp

}

export async function InstallProductBadge(token, integrationID) {
    var url = "/api/user/integration/" + integrationID + "/badge"
    var resp = await HTTPPost(url, token, {
        "type": "product",
    })
    if (resp.status != 200) {
        return null
    }

    resp = await GetAllIntegrations(token)
    if (resp == null) {
        return null
    }

    return resp

}

export async function LoadModalScript(src, websiteID) {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = src;
          document.head.appendChild(script);
        }
}
export async function LoadBadgeCSS(websiteID){
        const baseID = "SproutBaseStylesheet";
        const uri = `/ss/scripts/css/badge_base?websiteID=${websiteID}`;
        var t = await HTTPGetText(uri, null, null);
        t = t.data
        // Check if the stylesheet is already loaded
        var isLoaded = false;
        var links = document.getElementsByTagName("link");
        for (var i = 0; i < links.length; i++) {
          if (links[i].id === baseID) {
            isLoaded = true;
            break;
          }
        }
        // If the stylesheet is not loaded, add it to the document head
        if (!isLoaded) {
          var link = document.createElement("style");
          link.innerHTML = t;
          link.id = baseID;
          document.head.appendChild(link);
        }
      
}

async function GetAddIntegrationURL(token, integrationType) {
    const resp = await HTTPGet(
        "/api/squarespace/integration",
        token,
        null
      );

        if (resp.status != 200) {
            return null
        }

        return resp.data.URL
}


async function GetAddIntegrationURLWithWebsiteID(token, integrationType, websiteID) {
    const resp = await HTTPGet(
        "/api/squarespace/integration",
        token,
        {
            websiteID: websiteID
        }
      );

        if (resp.status != 200) {
            return null
        }

        return resp.data.URL
}

async function GetAllIntegrations(token) {
    var resp = await HTTPGet("/api/user/integrations", token, {})
    if (resp.status != 200) {
        alert("Failed", resp.status)
        return null
    }

    return resp.data


}

async function SaveImpactSettings(token, integrationID, impactSettings) {
    console.log("Saving impact settings:", impactSettings);
    var url = "/api/user/integration/" + integrationID + "/impact_setting"
    var resp = await HTTPPost(url, token, {
        tree_planting: impactSettings.tree_planting || false,
        plastic_removal: impactSettings.plastic_removal || false,
        selected_option: impactSettings.selected_option,
        num_trees: parseInt(impactSettings.num_trees) || 0,
        num_bottles: parseInt(impactSettings.num_bottles) || 0,
        thresholdAmt: Math.floor(parseFloat(impactSettings.minimum_order_amount)*100),
    })
    if (resp.status != 200) {
        return false
    }

    resp = await GetAllIntegrations(token)
    if (resp == null) {
        return false
    }

    return resp
}


async function GetAccountSettings(token) {
   var resp = await HTTPGet("/api/user", token, {})
    if (resp.status != 200) {        
        return null
    }

    return resp.data
}

async function GetSproutProductBadgeRender(token, integrationID) {
    var url = "/ss/api/badge/product"
    var resp = await HTTPGetText(url, token, {"integrationID": integrationID})
    if (resp.status != 200) {
        return null
    }
    
    return resp.data
}

export { GetTreeCountBadgeRender,GetAccountSettings, SaveImpactSettings, GetAddIntegrationURL, GetAddIntegrationURLWithWebsiteID, GetSproutProductBadgeRender}