import React, { useEffect } from 'react';

export function useFormStore(compStore) {
    // Function to initialize formStore based on compStore
    function getDefaultFormStore(compStore) {
        return {
            num_trees: compStore.current_integration?.impact_settings.find(s => s.tree_planting)?.tree_planting?.num_trees ?? 0,
            planting_option: compStore.current_integration?.impact_settings.find(s => s.tree_planting)?.tree_planting?.selected_tree_planting_option || "per_product",
            minimum_order_amount: getMinimumOrderAmount(compStore),
            selected_option: getSelectedOption(compStore),
            minimum_order_amount_enabled: getMinimumOrderAmountEnabled(compStore),
            environmental_impacts: compStore?.current_integration?.environmental_impacts || ["tree_planting"],
            num_bottles: compStore.current_integration?.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.num_bottles ?? 0,
            plastic_removal_option: compStore.current_integration?.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.selected_option || "per_product",
        };
    }

    const [formStore, setFormStore] = React.useState(getDefaultFormStore(compStore));

    React.useEffect(() => {
        setFormStore(getDefaultFormStore(compStore));
    }, [compStore?.current_integration]);

    return [formStore, setFormStore];
}


export function useAppSettingsDiff(compStore, formStore, setUnsavedChanges) {
    React.useEffect(() => {
      // Skip if no impact settings
      if (!compStore.current_integration?.impact_settings) {
        setUnsavedChanges("");
        return;
      }
  
      const treePlantingSettings = compStore.current_integration?.impact_settings.find(s => s.tree_planting)?.tree_planting;
      const plasticRemovalSettings = compStore.current_integration?.impact_settings.find(s => s.plastic_removal)?.plastic_removal;
      const currentEnvironmentalImpacts = compStore.current_integration?.environmental_impacts || ["tree_planting"];
  
      const comparisonData = {
        environmentalImpacts: {
          current: currentEnvironmentalImpacts,
          form: formStore.environmental_impacts
        },
        treePlanting: {
          current: {
            num_trees: treePlantingSettings?.num_trees ?? 0,
            selected_option: treePlantingSettings?.selected_option || "per_product"
          },
          form: {
            num_trees: formStore.num_trees,
            selected_option: formStore.selected_option
          }
        },
        plasticRemoval: {
          current: {
            num_bottles: plasticRemovalSettings?.num_bottles ?? 0,
            selected_option: plasticRemovalSettings?.selected_option || "per_product"
          },
          form: {
            num_bottles: formStore.num_bottles,
            selected_option: formStore.selected_option
          }
        },
        minimumOrder: {
          current: {
            enabled: getMinimumOrderAmountEnabled(compStore),
            amount: Number(getMinimumOrderAmount(compStore))
          },
          form: {
            enabled: formStore.minimum_order_amount_enabled ?? false,
            amount: Number(formStore.minimum_order_amount ?? 0)
          }
        }
      };

      let diffReasons = [];
      
      // Check environmental impacts
      if (JSON.stringify([...comparisonData.environmentalImpacts.current].sort()) !== 
          JSON.stringify([...comparisonData.environmentalImpacts.form].sort())) {
        diffReasons.push('Environmental impacts changed');
      }

      // Check tree planting settings
      if (formStore.environmental_impacts.includes("tree_planting")) {
        if (comparisonData.treePlanting.current.num_trees !== comparisonData.treePlanting.form.num_trees) {
          diffReasons.push('Number of trees changed');
        }
        if (comparisonData.treePlanting.current.selected_option !== comparisonData.treePlanting.form.selected_option) {
          diffReasons.push('Tree planting option changed');
        }
      }

      // Check plastic removal settings
      if (formStore.environmental_impacts.includes("plastic_removal")) {
        if (comparisonData.plasticRemoval.current.num_bottles !== comparisonData.plasticRemoval.form.num_bottles) {
          diffReasons.push('Number of bottles changed');
        }
        if (comparisonData.plasticRemoval.current.selected_option !== comparisonData.plasticRemoval.form.selected_option) {
          diffReasons.push('Plastic removal option changed');
        }
      }

      // Check minimum order settings
      if (comparisonData.minimumOrder.current.enabled !== comparisonData.minimumOrder.form.enabled) {
        diffReasons.push('Minimum order enabled/disabled changed');
      }
      if (Number(comparisonData.minimumOrder.current.amount) !== Number(comparisonData.minimumOrder.form.amount)) {
        diffReasons.push('Minimum order amount changed');
      }

      const diff = diffReasons.length > 0;
      
      // Log the comparison data and differences
      console.log('Settings Comparison:', {
        current: {
          environmentalImpacts: comparisonData.environmentalImpacts.current,
          treePlanting: comparisonData.treePlanting.current,
          plasticRemoval: comparisonData.plasticRemoval.current,
          minimumOrder: comparisonData.minimumOrder.current
        },
        form: {
          environmentalImpacts: comparisonData.environmentalImpacts.form,
          treePlanting: comparisonData.treePlanting.form,
          plasticRemoval: comparisonData.plasticRemoval.form,
          minimumOrder: comparisonData.minimumOrder.form
        },
        differences: diffReasons
      });
   
      setUnsavedChanges(diff ? "You have unsaved changes" : "");
    }, [compStore.current_integration, formStore, setUnsavedChanges]);
}

export function getDefaultFormStore(compStore) {
    return {
        num_trees: compStore?.current_integration?.impact_settings.find(s => s.tree_planting)?.tree_planting?.num_trees || 0,
        planting_option: compStore.current_integration?.impact_settings.find(s => s.tree_planting)?.tree_planting?.selected_option || "per_product",
        minimum_order_amount: getMinimumOrderAmount(compStore),
        minimum_order_amount_enabled: getMinimumOrderAmountEnabled(compStore),
        environmental_impacts: compStore?.current_integration?.environmental_impacts || ["tree_planting"],
        selected_option: getSelectedOption(compStore),
        num_bottles: compStore?.current_integration?.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.num_bottles || 0,
        plastic_removal_option: compStore.current_integration?.impact_settings.find(s => s.plastic_removal)?.plastic_removal?.selected_option || "per_product",
    };
}

export function getSelectedOption(compStore) { 
    const treePlantingOption = compStore.current_integration?.impact_settings?.find(s => s.tree_planting)?.tree_planting?.selected_option;
    const plasticRemovalOption = compStore.current_integration?.impact_settings?.find(s => s.plastic_removal)?.plastic_removal?.selected_option;
    return treePlantingOption || plasticRemovalOption || "per_product";
}

export function getMinimumOrderAmount(compStore) {
    const tree = compStore.current_integration?.impact_settings?.find(s => s.tree_planting)?.tree_planting?.threshold_amt
    const plastic = compStore.current_integration?.impact_settings?.find(s => s.plastic_removal)?.plastic_removal?.threshold_amt
    return tree/100 || plastic/100 || 0;
}

export function getMinimumOrderAmountEnabled(compStore) {
    const tree = compStore.current_integration?.impact_settings?.find(s => s.tree_planting)?.tree_planting?.threshold_amt > 0
    const plastic = compStore.current_integration?.impact_settings?.find(s => s.plastic_removal)?.plastic_removal?.threshold_amt > 0;
    return tree || plastic || false;
}


export function getCostPerTreeInCents(compStore) {
    const treePlantingCost = compStore?.current_integration?.impact_settings?.find(s => s.tree_planting)?.tree_planting?.cost_per_tree;
    return treePlantingCost || 50;
}


export function getCostPerBottleInCents(compStore) {
    const plasticRemovalCost = compStore?.current_integration?.impact_settings?.find(s => s.plastic_removal)?.plastic_removal?.cost_per_bottle;
    return plasticRemovalCost || 8;
}