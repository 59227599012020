import React from 'react';
import { Box, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Text } from "@chakra-ui/react";

function PlasticRemovalSettings({ formStore, setFormStore }) {
  const [error, setError] = React.useState("");

  const validateBottles = (value) => {
    if (formStore.selected_option === "fixed_monthly" && value < 10) {
      setError("Monthly subscriptions require a minimum of 10 plastic bottles");
      return false;
    }
    if (value <= 0) {
      setError("Please enter a number of plastic bottles greater than 0");
      return false;
    }
    setError("");
    return true;
  };

  React.useEffect(() => {
    if (formStore.environmental_impacts.includes("plastic_removal")) {
      const isValid = validateBottles(formStore.num_bottles);
      setFormStore(prev => ({
        ...prev,
        hasValidationError: !isValid
      }));
    }
  }, [formStore.selected_option, formStore.num_bottles]);

  return (
    formStore.environmental_impacts.includes("plastic_removal") && (
      <Box mb={4}>
        <FormLabel fontWeight="medium">Number of plastic bottles</FormLabel>
        <NumberInput
          value={formStore.num_bottles}
          min={formStore.selected_option === "fixed_monthly" ? 10 : 0}
          isInvalid={error !== ""}
          onChange={(valueString) => {
            const value = valueString === '' ? 0 : Number(valueString);
            const isValid = validateBottles(value);
            setFormStore({
              ...formStore,
              num_bottles: value,
              hasValidationError: !isValid
            });
          }}
        >
          <NumberInputField bgColor="white" borderRadius="10" />
          <NumberInputStepper pr="2">
            <NumberIncrementStepper color="gray.400" border="0" />
            <NumberDecrementStepper color="gray.400" border="0" />
          </NumberInputStepper>
        </NumberInput>
        {error && (
          <Text fontSize="sm" color="red.500" mt={2}>
            {error}
          </Text>
        )}
      </Box>
    )
  );
}

export default PlasticRemovalSettings; 