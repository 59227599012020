import React from 'react';
import { FormControl, FormLabel, RadioGroup, Radio, Stack, Box } from "@chakra-ui/react";

function ImpactSettingsRadio({ formStore, setFormStore, compStore }) {
  const handleOptionChange = (value) => {
    console.log("Selected option:", value);
    const updatedFormStore = {
      ...formStore,
      selected_option: value,
      planting_option: value,
      plastic_removal_option: value,
      hasValidationError: false
    };

    // Reset validation states based on new option
    if (value === "fixed_monthly") {
      updatedFormStore.showMinTreesMessage = formStore.num_trees < 10;
      updatedFormStore.showMinPlasticBottlesMessage = formStore.num_bottles < 10;
      updatedFormStore.hasValidationError = 
        (formStore.environmental_impacts.includes("tree_planting") && formStore.num_trees < 10) ||
        (formStore.environmental_impacts.includes("plastic_removal") && formStore.num_bottles < 10);
    } else {
      updatedFormStore.showMinTreesMessage = false;
      updatedFormStore.showMinPlasticBottlesMessage = false;
    }

    setFormStore(updatedFormStore);
  };

  return (
    <Box mb={8}>
      <FormControl>
        <FormLabel fontWeight="medium">I want to:</FormLabel>
        <RadioGroup
          colorScheme="teal"
          value={formStore.selected_option}
          onChange={handleOptionChange}
        > 
          <Stack spacing="8px">
            <Radio value="per_product">
              {formStore.environmental_impacts.includes("tree_planting") && formStore.environmental_impacts.includes("plastic_removal") ?
              "Plant trees and remove plastic bottles for every product sold" :
              formStore.environmental_impacts.includes("tree_planting") ? "Plant trees for every product sold" : "Remove plastic bottles for every product sold"
              }
            </Radio>
            <Radio value="per_order">
              {formStore.environmental_impacts.includes("tree_planting") && formStore.environmental_impacts.includes("plastic_removal") ?
              "Plant trees and remove plastic bottles for every order" :
              formStore.environmental_impacts.includes("tree_planting") ? "Plant trees for every order" : "Remove plastic bottles for every order"
              }
            </Radio>
            <Radio value="fixed_monthly">
               {formStore.environmental_impacts.includes("tree_planting") && formStore.environmental_impacts.includes("plastic_removal") ?
              "Plant trees and remove plastic bottles every month" :
              formStore.environmental_impacts.includes("tree_planting") ? "Plant trees every month" : "Remove plastic bottles every month"
              }
            </Radio>
            {compStore.current_integration?.status === "active" ? (
              <Radio value="pause">
                Pause
              </Radio>
            ) : (
              <Radio value="pause" isDisabled>
                Pause
              </Radio>
            )}
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default ImpactSettingsRadio; 