import React from 'react';
import {
    Box,
    Text,
    Stack,
} from "@chakra-ui/react";

function AppSettingsSidebar({ compStore, unsavedChanges, saveButton, formStore }) {
  return (
    <Box w={{ base: "100%", md: "33%", lg: "33%" }}>
      <Box
        mb={6}
        bgColor="#F4F8FB"
        borderRadius="24"
        p={{ base: "4", md: "8", lg: "8" }}
      >
        <Box mb={6}>
          <Text fontSize="xl" fontWeight="medium" mb={2}>
            App settings
          </Text>
          <Text color="gray.500" mb={4}>
            Once activated, Sprout will automatically track orders in your store and calculate the number of trees to plant and plastic bottles to remove based on your settings
          </Text>
        </Box>
        <Box mb={4}>
          <Stack>
             {(formStore.planting_option == "pause" && formStore.environmental_impacts.includes("tree_planting")) || (formStore.plastic_removal_option == "pause" && formStore.environmental_impacts.includes("plastic_removal")) ? (
              <>
              <Box bg="white" p={3}>
                <Text>No trees or plastic bottles tracked and charged for</Text>
              </Box>
              <Box bg="white" p={3}>
                <Text>Banners disabled</Text>
              </Box>
              </>
            ):(
              <>
               {formStore.environmental_impacts.includes("tree_planting") && (
                <Box bg="white" p={3}>
                  <Text>Cost ${compStore.costPerTree}0 USD per tree planting </Text>
                </Box>
              )}
              {formStore.environmental_impacts.includes("plastic_removal") && (
                <Box bg="white" p={3}>
                  <Text>Cost ${compStore.costPerBottle} USD per plastic bottle removal </Text>
                </Box>
              )}
              </>
            )}


          </Stack>
        </Box>
        {saveButton()}
        <br/>
        <Text color={"red.500"}> {unsavedChanges} </Text>
      </Box>
    </Box>
  );
}

export default AppSettingsSidebar; 