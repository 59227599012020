import React from 'react';
import { Box, FormControl, FormLabel, Checkbox, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Text } from "@chakra-ui/react";

function MinimumOrderAmountSettings({ formStore, setFormStore }) {
  const [error, setError] = React.useState("");

  const validateAmount = (value) => {
    if (value <= 0) {
      setError("Minimum order amount must be greater than 0");
      return false;
    }
    setError("");
    return true;
  };

  return (
    (formStore.planting_option === "per_order" || formStore.plastic_removal_option === "per_order") && (
      <Box mb={4}>
        <FormControl display="flex" alignItems="center" mb={2}>
          <Checkbox
            id="minimum_order_amount_enabled"
            mr="2"
            isChecked={formStore.minimum_order_amount_enabled}
            onChange={(e) => setFormStore({ 
              ...formStore, 
              minimum_order_amount_enabled: e.target.checked,
              minimum_order_amount: e.target.checked ? formStore.minimum_order_amount : 0
            })}
          />
          <FormLabel htmlFor="minimum_order_amount_enabled" fontWeight="medium" mb="0">
            Set a minimum order amount
          </FormLabel>
        </FormControl>
        {formStore.minimum_order_amount_enabled && (
          <Box>
            <FormLabel fontWeight="medium">Minimum order amount ($)</FormLabel>
            <NumberInput
              min={1}
              precision={2}
              step={1.0}
              value={formStore.minimum_order_amount}
              isInvalid={error !== ""}
              onChange={(value) => {
                const numValue = parseFloat(value);
                validateAmount(numValue);
                setFormStore({ 
                  ...formStore, 
                  minimum_order_amount: numValue,
                  hasValidationError: !validateAmount(numValue)
                });
              }}
            >
              <NumberInputField bgColor="white" borderRadius="10" />
              <NumberInputStepper>
                <NumberIncrementStepper color="gray.400" border="0" />
                <NumberDecrementStepper color="gray.400" border="0" />
              </NumberInputStepper>
            </NumberInput>
            {error && (
              <Text fontSize="sm" color="red.500" mt={2}>
                {error}
              </Text>
            )}
          </Box>
        )}
      </Box>
    )
  );
}

export default MinimumOrderAmountSettings; 