import { useState } from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  Spinner,
  useColorModeValue,
  useColorMode,
  useDisclosure,
  Menu,
  MenuButton,
  UnorderedList,
  ListItem,
  SimpleGrid,
  TableCaption,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableHeaderGroup,
  TableFooterGroup,
  MenuList,
  MenuItem,
  Link,
} from "@chakra-ui/react";

import {CancelBilling, GetAccountSettings, RefreshSettings} from "../backend"
import {ChevronDownIcon} from "@chakra-ui/icons";
import { loadStripe } from "@stripe/stripe-js";


import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PostalCodeElement,
  useStripe,
  useElements,
  CardElement,
  Elements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { HTTPPost } from "../httputils";
import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {ReactComponent as SproutLogo} from './images/sprout-logo.svg';
import NavBar from "./nav-bar";
import { Navigate } from "react-router-dom";




const str = loadStripe("pk_live_51JQNUAJClwFJyX55URaskQ3JqHaLg6Sg3qQiGv4DZQRMZZ18KJvp14tri8mqbbiOPF5c5UXHmSYxHlKgSXYaP8SY00fS25nhSt")

function SubscriptionList({compStore, setCompStore}) {
  const [user, loading] = useAuthState(firebase.auth());
  const updateStatus = async (integration_id, status) => {
    if (!user && !loading) {
      return;
    }

   var resp = await HTTPPost(`/api/v1/integrations/${integration_id}/update_status`,
     user.getIdToken(true), 
      {
        integration_id: integration_id,
        status: status,
      })

      if (resp.status != 200) {
        return;
      }

      var new_integrations = compStore.connected_integrations.map((integration) => {
        if (integration.id == integration_id) {
          integration.status = status;
        }

        return integration;
      })

      setCompStore({
        ...compStore,
        connected_integrations: new_integrations,
      })
  }

   
   



  return (
    <Flex flexDirection={{ base: "column", md: "row", lg: "row" }} gap="0">
      
      <Box
        mb={0}
        bgColor="#F4F8FB"
        borderRadius="24"
        p={{ base: "4", md: "8", lg: "8" }}
        w={{ base: "100%", md: "100%", lg: "100%" }}

      >

      <Box mb={6}>
            <Text fontSize="xl" fontWeight="medium" mb={2}>
              Connected Integrations
            </Text>
            <Text lineHeight="150%" mb={8} textColor="gray.500">
              Any integrations(API, Sqaurespace etc.) you've connected for will show up here
            </Text>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Integration Name</Th>
                    <Th>Cost Per Tree</Th>
                    <Th>Cost Per Bottle</Th>
                    <Th>Status</Th>
                    {/* <Th>Actions</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                {
              function(){
                var l = []
                for (var i=0; i<compStore.connected_integrations.length; i++){
                  // ignore anything thats not setup
                  if (compStore.connected_integrations[i].status === "not_setup"){
                    continue;
                  }

                 l.push((
                  <Tr>
                  <Td>
                    {compStore.connected_integrations[i].human_name} 
                  </Td>
                  <Td>{
                  function() {
                      if (compStore.connected_integrations[i].status === "no_global_billing" || compStore.connected_integrations[i].status === "inactive"){
                        return "N/A"
                      }

                      if (!compStore.connected_integrations[i].impact_settings.find(s => s.tree_planting)){
                        return "N/A"
                      }

                      return compStore.connected_integrations[i].impact_settings.find(s => s.tree_planting)?.tree_planting?.cost_per_tree/100 + "0 USD Per Tree"
                    }()
                  }

                   
                  </Td>
                  <Td>
                    {
                      function() {
                        if (compStore.connected_integrations[i].status === "no_global_billing" || compStore.connected_integrations[i].status === "inactive"){
                          return "N/A"
                        }

                        if (!compStore.connected_integrations[i].impact_settings.find(s => s.plastic_removal)){
                          return "N/A"
                        }
                        return compStore.connected_integrations[i].impact_settings.find(s => s.plastic_removal)?.plastic_removal?.cost_per_bottle/100 + " USD Per Bottle"
                      }()
                    }
                  </Td>
                  <Td>
                  {
                    function() {
                      if (compStore.connected_integrations[i].status === "no_global_billing"){
                        return "No Billing Setup On Account"
                      }

                      return compStore.connected_integrations[i].status
                    }()
                   }
                  </Td>
                  {/* <Td>
                   {
                    function() {
                      var available_actions = "Pause"
                      var current_status = compStore.connected_integrations[i].status
                      if (current_status === "no_global_billing"){
                        return "N/A"
                      } else if (current_status === "active"){
                          available_actions = "Pause"
                      } else if (current_status === "inactive"){
                          available_actions = "Resume"
                      } else {
                        available_actions = "N/A"
                      }


                      return (
                        <Menu>
                        <MenuButton as={Button} cursor="pointer" rightIcon={<ChevronDownIcon />}>
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem>{available_actions}</MenuItem>
                        </MenuList>
                      </Menu>
                        
                        
                      )


                    }()
                   }
                  
                  </Td> */}
                  </Tr>
                  ))
                }

                return l
              }()




            }

                  </Tbody>
              </Table>
            </TableContainer>
         
          </Box>

        </Box>
    <Box>
    </Box>
    </Flex>
  );
}


  

function EditForm({compStore, setCompStore, setEditMode}) {
  const [user, loading] = useAuthState(firebase.auth());
  const [isCanceling, setIsCanceling] = useState(false)

  const handleCancelBilling = async () => {
    setIsCanceling(true);

    try {
            // TODO: Add error handling

      await CancelBilling(user.getIdToken(true));
      setEditMode(false);
      await RefreshSettings(
        compStore.current_integration.uuid,
        compStore,
        setCompStore,
        user.getIdToken(true)
      );
      alert("Billing Cancelled, all active integrations set to inactive");
    } catch (error) {
      // Handle error if necessary
    }

    setIsCanceling(false);
  };


  if(!user && !loading) {
    return <Navigate to="/login" />
  }

  return (
      <Flex >
        <Box
          mb={0}
          bgColor="#F4F8FB"
          borderRadius="24"
          p={{ base: "4", md: "8", lg: "8" }}
          w={{ base: "100%", md: "100%", lg: "100%" }}
        >
  
        <Box mb={6}>
              <Text fontSize="xl" fontWeight="medium" mb={2}>
                
              </Text>
              <Text lineHeight="150%" mb={8} textColor="gray.500">
                Current Billing Details
              </Text>
              <TableContainer>
                <Table>
                  <TableCaption> <Link  onClick={handleCancelBilling} fontSize={"16px"} color={"teal.500"}> 
                  
                  
                  {isCanceling ? (
          <>
            <Spinner size="sm" mr="2" color="teal.500" />
            Cancelling...
          </>
        ) : (
          "Cancel Billing"
        )}
                  
                  
                  
                   </Link></TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Card Details</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        **** **** **** {compStore.global_account_settings.billing.last4}
                      </Td>
                      <Td>
                        <Button onClick={
                          function() {
                            setEditMode(true)
                          }
                         }size={"sm"} colorScheme={"teal"} >
                          Edit
                        </Button>
                      </Td>
                    </Tr>  
                    </Tbody>
                </Table>
              </TableContainer>
           
            </Box>
  
          </Box>
      <Box>
      </Box>
      </Flex>
  )
}


 function BillingForm({compStore, setCompStore, inEditMode, setInEditMode}) {
  const [user, loading] = useAuthState(firebase.auth());
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [addressCompleted, setAddressCompleted] = useState(false);
  const [paymentSaved, setPaymentSaved] = useState(false);

  const handleSubmit = async (event) => {
    if (!user && !loading) {
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    setError(null);


    if (!addressCompleted) {
      alert("Please complete your address")
      setIsLoading(false);
      return;
    }
    
    // Validate form data
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
 
    // Create a PaymentMethod object from the card element
    const cardElement = elements.getElement(CardElement);
    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: address
    }
     
    });
    
    if (stripeError) {
      alert(stripeError.message)
      setError(stripeError.message);
      setIsLoading(false);
      return;
    }

    // Send the PaymentMethod to your server to create a subscription
    // or a customer object
    var resp = await HTTPPost(`/api/user/billing`, user.getIdToken(true), {
      paymentMethod
    })
    setIsLoading(false);

    if (resp.status != 200) {
      showToast({ title: "Error saving billing information.", status: "error" });
      return
    }

   
    var accSettings = await GetAccountSettings(user.getIdToken(true))
    setCompStore({...compStore, global_account_settings: accSettings})
  


    

    showToast({ title: "Billing information saved.", status: "success" });
    setInEditMode(false)

  };

  const showToast = useToast();

  return (
    
    <Flex flexDirection={{ base: "column", md: "row", lg: "row" }} gap="6">
      <Box w={{ base: "100%", md: "67%", lg: "67%" }}>
        <Box
          mb={0}
          bgColor="#F4F8FB"
          borderRadius="24"
          p={{ base: "4", md: "8", lg: "8" }}
        >
          <Box mb={6}>
            <Text fontSize="xl" fontWeight="medium" mb={2}>
              Billing Information
            </Text>
            <Text lineHeight="150%" mb={8} textColor="gray.500">
              Enter your billing information for Sprout. Sprout will bill your
              preferred method of payment every month (30 days).
            </Text>
          </Box>
          <Box mb={8}>
            <FormControl>
              <FormLabel>Billing Details </FormLabel>
            < Box>
            <AddressElement
  options={{
    autocomplete: {
      mode: "automatic",
    },
    mode: "billing",
  }}
  onChange={(event) => {
    if (event.complete) {
      setAddress(event.value.address);
      setAddressCompleted(true);
    } else {
      setAddressCompleted(false);
    }
  }}
/>

                </Box>
                <br/>

              <FormLabel>Credit card number</FormLabel>
              <Box
                mb={4}
                bgColor="white"
                borderRadius="10"
                boxShadow="sm"
                borderWidth="1px"
                borderColor={useColorModeValue("gray.200", "gray.700")}
                p          ={2}
                >
                  <CardElement
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: "16px",
                          color: useColorModeValue("gray.700", "gray.50"),
                          "::placeholder": {
                            color: useColorModeValue("gray.400", "gray.300"),
                          },
                        },
                        invalid: {
                          color: useColorModeValue("red.500", "red.400"),
                        },
                      },
                    }}
                    
                  />

                  
                </Box>
               
                {error ? (
              <Text mt={2} fontSize="sm" color="red.500">
               {error}
              </Text>
            ) : null}
               
              </FormControl>
              <Button
              colorScheme="teal"
              width="100%"
              onClick={handleSubmit}
              disabled={!stripe || isLoading}
            >
              Save
              {isLoading ? <Spinner size="sm" mr={2} /> : null}
              {paymentSaved ? <span style={{ marginLeft: '8px' }}>✅</span> : null}
              <br/>
            </Button>
            <Text color="gray.500" mb={4}>
                You will only be charged for the integrations you have activated at the price mentioned per integration.
              </Text>
            {error ? (
              <Text mt={2} fontSize="sm" color="red.500">
               {error}
              </Text>
            ) : null}
            { inEditMode  ? (
            <Link onClick={()=>{
              setInEditMode(false)
            }}color="teal.500" mb={4}> Back </Link>
            ) : null}
            </Box>
          </Box>
        </Box>
       
      </Flex>
);
}      








export default function Billing({compStore, setCompStore}) {
    
    const [inEditMode, setInEditMode] = useState(false);

    return (
        <Elements stripe={str}>
          {
          function() {
          if (compStore.global_account_settings.billing.status == "active" && inEditMode == false) {
              return <EditForm compStore={compStore} setCompStore={setCompStore} setEditMode={setInEditMode}/>
            }
          
          
         return <BillingForm compStore={compStore} setCompStore={setCompStore} inEditMode={inEditMode} setInEditMode={setInEditMode}/>
        }()
         }
        <br/>
        <SubscriptionList compStore={compStore} setCompStore={setCompStore}/>
        </Elements>
    );
    }